@import url(https://fonts.googleapis.com/css2?family=B612:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;700&display=swap);
#mapId{height:100vh;width:100%}.leaflet-container{font-size:100%}.leaflet-container .leaflet-popup{transition:opacity .4s ease-in}.leaflet-container .leaflet-popup .leaflet-popup-content-wrapper{border-radius:0;padding:1rem;box-shadow:0 15px 15px rgba(0,0,0,0.1);background-color:#2a2c39;color:#ffeac9}.leaflet-container .leaflet-popup .leaflet-popup-content-wrapper .leaflet-popup-content{margin:0;width:auto !important;min-width:12rem}.leaflet-container .leaflet-popup .leaflet-popup-content-wrapper .leaflet-popup-content h3{margin:0 0 .5rem 0}.leaflet-container .leaflet-popup .leaflet-popup-tip{background:#2a2c39}.leaflet-container .leaflet-tooltip{font-size:0.8;border-radius:0;border:0;padding:1rem;box-shadow:0 15px 15px rgba(0,0,0,0.1);background-color:#2a2c39;color:#ffeac9;transition:opacity 0.7s cubic-bezier(0.63, -0.33, 0.32, 1.4)}@media only screen and (max-width: 1024px){.leaflet-container .leaflet-tooltip{display:none}}.leaflet-container .leaflet-tooltip:before{display:none}.leaflet-container .leaflet-tooltip h3{font-size:1.2rem;margin-bottom:0.2em}.leaflet-container .leaflet-control-zoom{box-shadow:none}.leaflet-container .leaflet-control-zoom a{border-radius:0;height:auto;width:auto;padding:0.3rem 0.7rem;margin-bottom:2px;border:none;box-shadow:0 15px 15px rgba(0,0,0,0.1)}.leaflet-container a.leaflet-popup-close-button{font-size:1.5rem;padding:.5rem;width:auto;height:auto}

.info{z-index:500;position:absolute;height:2rem;bottom:1rem;right:1rem;opacity:1;line-height:2rem;font-size:0.7rem;width:auto}@media only screen and (max-width: 1024px){.info{height:auto;min-height:2rem;line-height:1.2;font-size:0.6rem}}.info:hover{opacity:1}.info:hover .info__container{opacity:1;width:auto;padding:0 2.5rem 0 1rem}@media only screen and (max-width: 1024px){.info:hover .info__container{padding:0.6rem 2.5rem 0.6rem 1rem}}.info:hover .toggle-info{box-shadow:none;background-color:#2a2c39}.info--open{opacity:1}.info__container{transition:opacity 0.1s ease, width 0.8s ease;padding:0;background:#2a2c39;color:#ffeac9;border-radius:25px;opacity:0;width:0;box-shadow:0 10px 15px 0 rgba(0,0,0,0.1);height:100%}.info__container a{color:inherit;font-weight:bold}.toggle-info{width:2rem;height:2rem;z-index:501;bottom:0;right:0;position:absolute;background-color:white;border-radius:50%;background-repeat:no-repeat;background-position:center center;cursor:pointer;box-shadow:0 10px 15px 0 rgba(0,0,0,0.1);-webkit-tap-highlight-color:rgba(0,0,0,0);transform:rotate(0deg);transition:.2s ease;text-align:center;line-height:2rem;color:#cd1719}

.menu{z-index:500;position:absolute;width:100%;top:.7rem;left:1rem;max-width:25rem;width:calc(100% - 2rem);transform:translate(-27rem, 0);transition:transform 0.2s ease-in-out}.menu--open{transform:translate(0, 0)}.menu__container{background:#2a2c39;color:#ffeac9;border-radius:25px;margin:.3rem auto 1rem;padding:.5rem 1.5rem 2rem 1.5rem;box-shadow:0 10px 15px 0 rgba(0,0,0,0.1);height:auto;min-height:10rem;width:100%}.menu__container a{color:inherit;font-weight:bold}.legend{padding-top:3.5rem}.legend__title{margin-bottom:1rem}.legend__item{display:flex;align-items:center;margin-bottom:.5rem}.legend__item .value{height:2rem;width:3rem;background-color:#2a2c39;margin-right:1rem}.legend__item .value--red{background-color:#cd1719}.legend__item .value--orange{background-color:#ff9b81}.legend__item .value--yellow{background-color:#ffeac9}.legend .cta{margin-top:1rem}.toggle-menu{width:3.2rem;height:3.2rem;z-index:501;top:1.5rem;left:1.5rem;position:absolute;background-color:white;border-radius:50%;background-repeat:no-repeat;background-position:center center;cursor:pointer;box-shadow:0 10px 15px 0 rgba(0,0,0,0.1);-webkit-tap-highlight-color:rgba(0,0,0,0);transform:rotate(0deg);transition:.2s ease-in-out}.toggle-menu .lines{width:19px;height:1.2rem;position:absolute;margin-left:1rem}.toggle-menu span{display:block;position:absolute;height:2px;width:100%;background:#cd1719;border-radius:2px;opacity:1;left:0;transform:rotate(0deg);transition:.25s ease-in-out}.toggle-menu span:nth-child(1){top:18px;transform-origin:left center}.toggle-menu span:nth-child(2){top:24px;transform-origin:left center}.toggle-menu span:nth-child(3){top:30px;transform-origin:left center}.toggle-menu--open{box-shadow:none;background-color:#2a2c39}.toggle-menu--open span:nth-child(1){transform:rotate(45deg);top:18px;left:2px}.toggle-menu--open span:nth-child(2){width:0%;opacity:0}.toggle-menu--open span:nth-child(3){transform:rotate(-45deg);top:31.5px;left:2px}

html,body,div,span,applet,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,acronym,address,big,cite,code,del,dfn,em,img,ins,kbd,q,s,samp,small,strike,strong,sub,sup,tt,var,b,u,i,center,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,table,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,embed,figure,figcaption,footer,header,hgroup,menu,nav,output,ruby,section,summary,time,mark,audio,video{margin:0;padding:0;border:0;font-size:100%;font:inherit;vertical-align:baseline}article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section{display:block}body{line-height:1}ol,ul{list-style:none}blockquote,q{quotes:none}blockquote:before,blockquote:after{content:'';content:none}q:before,q:after{content:'';content:none}table{border-collapse:collapse;border-spacing:0}*,html{box-sizing:border-box}*,*:before,*:after{box-sizing:inherit}*,*:before,*:after{box-sizing:border-box}body,html{width:100%;margin:0;font-size:16px;font-family:'PT Sans', sans-serif;color:#000;font-weight:400;background-color:#ffffff}h1,h2,h3,h4,h5,h6{margin-bottom:.5rem;font-weight:700;line-height:1.2;letter-spacing:0.41px;font-family:'B612', sans-serif}h1{margin-bottom:2rem;font-weight:300;font-size:2.5rem}h2{font-size:1.75rem}h3{font-size:1.5rem}h4{font-size:1.25rem}strong,b{font-weight:bold}small{font-size:80%;font-weight:400}.watermark{color:#002560;opacity:.4;text-decoration:none;position:fixed;bottom:.5rem;left:.5rem;z-index:401;font-weight:500}.map-loader{position:absolute;top:0;bottom:0;left:0;right:0;width:100%;height:100%;background-color:#c3cdd4}.map-loader .spinner{position:absolute;top:0;bottom:0;right:0;left:0;margin:auto;width:60px;height:60px;border:10px solid rgba(0,0,0,0.2);border-left-color:#e8edf0;border-radius:50%;-webkit-animation:AppLoader 1.1s linear infinite;animation:AppLoader 1.1s linear infinite}@-webkit-keyframes AppLoader{0%{transform:rotate(0deg)}to{transform:rotate(1turn)}}@keyframes AppLoader{0%{transform:rotate(0deg)}to{transform:rotate(1turn)}}

