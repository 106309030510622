@import '../../scss/variables';


.info {
    z-index: 500;
    position: absolute;
    height: 2rem;
    bottom: 1rem;
    right: 1rem;
    opacity: 1;
    line-height: 2rem;
    font-size: 0.7rem;
    width: auto;

    @media only screen and (max-width: 1024px) {
        height: auto;
        min-height: 2rem;
        line-height: 1.2;
        font-size: 0.6rem;
    }

    &:hover {
        opacity: 1;
        .info__container {
            opacity: 1;
            width: auto;
            padding: 0 2.5rem 0 1rem;
            @media only screen and (max-width: 1024px) {
                padding: 0.6rem 2.5rem 0.6rem 1rem
            }
        }
        .toggle-info {
            box-shadow: none;
            background-color: $dark;
        }
    }
    &--open {
        // width: auto;
        opacity: 1;
    }

    &__container {
        transition: opacity 0.1s ease, width 0.8s ease;
        padding: 0;
        background: $dark;
        color: $alt2;
        border-radius: 25px;
        opacity: 0;
        width: 0;
        box-shadow: 0 10px 15px 0 rgba(0, 0, 0, .1);
        height: 100%;

        a {
            color: inherit;
            font-weight: bold;
        }
    }
}

.toggle-info {
    width: 2rem;
    height: 2rem;
    z-index: 501;
    bottom: 0;
    right: 0;
    position: absolute;
    background-color: white;
    border-radius: 50%;
    background-repeat: no-repeat;
    background-position: center center;
    cursor: pointer;
    box-shadow: 0 10px 15px 0 rgba(0, 0, 0, 0.1);

    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);

    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .2s ease;
    -moz-transition: .2s ease;
    -o-transition: .2s ease;
    transition: .2s ease;

    text-align: center;
    line-height: 2rem;
    color: $red;
}