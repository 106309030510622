@import './scss/reset';
@import './scss/variables';
@import url('https://fonts.googleapis.com/css2?family=B612:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=PT+Sans:wght@400;700&display=swap');

*,
*:before,
*:after {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

body,
html {
  width: 100%;
  margin: 0;
  font-size: 16px;
  font-family: 'PT Sans', sans-serif;
  color: $text-color;
  font-weight: 400;
  background-color: #ffffff;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: .5rem;
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: 0.41px;
  font-family: 'B612', sans-serif;
}

h1 {
  margin-bottom: 2rem;
  font-weight: 300;
  font-size: 2.5rem;
}

h2 {
  font-size: 1.75rem;
}

h3 {
  font-size: 1.5rem;
}

h4 {
  font-size: 1.25rem;
}

strong,
b {
  font-weight: bold;
}

small {
  font-size: 80%;
  font-weight: 400;
}

.watermark {
  color: #002560;
  opacity: .4;
  text-decoration: none;
  position: fixed;
  bottom: .5rem;
  left: .5rem;
  z-index: 401;
  font-weight: 500;
}

.map-loader {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background-color: #c3cdd4;
  .spinner {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    width: 60px;
    height: 60px;
    border: 10px solid rgba(0, 0, 0, .2);
    border-left-color: #e8edf0;
    border-radius: 50%;
    animation: AppLoader 1.1s linear infinite;
  }
}

@keyframes AppLoader {
  0% {
    transform: rotate(0deg)
  }

  to {
    transform: rotate(1turn)
  }
}