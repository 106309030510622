@import '../../scss/variables';


.menu {
    z-index: 500;
    position: absolute;
    width: 100%;
    top: .7rem;
    left: 1rem;
    max-width: 25rem;
    width: calc(100% - 2rem);
    transform: translate(-27rem, 0);
    transition: transform 0.2s ease-in-out;


    &--open {
        transform: translate(0, 0);
    }

    &__container  {
        background: $dark;
        color: $alt2;
        border-radius: 25px;
        margin: .3rem auto 1rem;
        padding: .5rem 1.5rem 2rem 1.5rem;
        box-shadow: 0 10px 15px 0 rgba(0,0,0,.1);
        height: auto;
        min-height: 10rem;

        width: 100%;

        a {
            color: inherit;
            font-weight: bold;
        }
    }
}

.legend {
    padding-top: 3.5rem;
    &__title {
        margin-bottom: 1rem;
    }
    &__item {
        display: flex;
        align-items: center;
        margin-bottom: .5rem;
        .value {
            height: 2rem;
            width: 3rem;
            background-color: $dark;
            margin-right: 1rem;
            &--red {
                background-color: $red;
            }
            &--orange {
                background-color: $alt1;
            }
            &--yellow {
                background-color: $alt2;
            }
        }
        .label {
            
        }
    }
    .cta {
        margin-top: 1rem;
    }
}

.toggle-menu {
  width: 3.2rem;
  height: 3.2rem;
  z-index: 501;
  top: 1.5rem;
  left: 1.5rem;
  position: absolute;
  background-color: white;
  border-radius: 50%;
  background-repeat: no-repeat;
  background-position: center center;
  cursor: pointer;
  box-shadow: 0 10px 15px 0 rgba(0,0,0,0.1);

  -webkit-tap-highlight-color: rgba(0,0,0,0);

  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .2s ease-in-out;
  -moz-transition: .2s ease-in-out;
  -o-transition: .2s ease-in-out;
  transition: .2s ease-in-out;

  .lines {
    width: 19px;
    height: 1.2rem;
    position: absolute;
    margin-left: 1rem;
  }

  span {
    display: block;
    position: absolute;
    height: 2px;
    width: 100%;
    background: $red;
    border-radius: 2px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;

    &:nth-child(1) {
      top: 18px;
      -webkit-transform-origin: left center;
      -moz-transform-origin: left center;
      -o-transform-origin: left center;
      transform-origin: left center;
    }
    
    &:nth-child(2) {
      top: 24px;
      -webkit-transform-origin: left center;
      -moz-transform-origin: left center;
      -o-transform-origin: left center;
      transform-origin: left center;
    }
    
    &:nth-child(3) {
      top: 30px;
      -webkit-transform-origin: left center;
      -moz-transform-origin: left center;
      -o-transform-origin: left center;
      transform-origin: left center;
    }
  }

  &--open {
    box-shadow: none;
    background-color: $dark;
    span {
      &:nth-child(1) {
        -webkit-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -o-transform: rotate(45deg);
        transform: rotate(45deg);
        top: 18px;
        left: 2px;
      }
      
      &:nth-child(2) {
        width: 0%;
        opacity: 0;
      }
      
      &:nth-child(3) {
        -webkit-transform: rotate(-45deg);
        -moz-transform: rotate(-45deg);
        -o-transform: rotate(-45deg);
        transform: rotate(-45deg);
        top: 31.5px;
        left: 2px;
      }
    }
  }
}