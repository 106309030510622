@import '../../scss/variables';

#mapId {
    height: 100vh;
    width: 100%;
}

.leaflet-container {
    font-size: 100%;

    .leaflet-popup {
        transition: opacity .4s ease-in;
        .leaflet-popup-content-wrapper {
            border-radius: 0;
            padding: 1rem;
            box-shadow: 0 15px 15px rgba(0, 0, 0, 0.1);
            background-color: $dark;
            color: #ffeac9;

            .leaflet-popup-content {    
                margin: 0;
                width: auto !important;
                min-width: 12rem;

                h3 {
                    margin: 0 0 .5rem 0;
                }
            }
        }

        .leaflet-popup-tip {
            background: $dark;
        }
    }

    .leaflet-tooltip {
        font-size: 0.8;
        border-radius: 0;
        border: 0;
        padding: 1rem;
        box-shadow: 0 15px 15px rgba(0, 0, 0, 0.1);
        background-color: #2a2c39;
        color: $alt2;
        transition: opacity .7s cubic-bezier(.63,-0.33,.32,1.4);

        @media only screen and (max-width: 1024px) {
            display: none;
        }

        &:before {
            display: none;
        }

        h3 {
            font-size: 1.2rem;
            margin-bottom: 0.2em;
        }
    }

    .leaflet-control-zoom {
        box-shadow: none;
        a {
            border-radius: 0;
            height: auto;
            width: auto;
            padding: 0.3rem 0.7rem;
            margin-bottom: 2px;
            border: none;
            box-shadow: 0 15px 15px rgba(0, 0, 0, 0.1);
        }
    }

    a {
        &.leaflet-popup-close-button {
            font-size: 1.5rem;
            padding: .5rem;
            width: auto;
            height: auto;
        }
    }

}